
import { defineComponent } from '@vue/runtime-core';
import { pdfPath, tokenResult } from '@/Study/helpers';
import { setHasCorona } from '@/Study/module';
import FinalPage from '@/Study/Pages/Final.vue';

export default defineComponent({
    components: { FinalPage },
    computed: {
        hasCorona(){
            return this.$store.state.study!.hasCorona;
        },
        pdfPath(){
            return pdfPath();
        }
    },
    methods: {
        onLoad(data: tokenResult){
            setHasCorona(Boolean(data.hasCorona));
        }
    }
});
