
import { defineComponent } from '@vue/runtime-core';
import { sendMessage } from '../helpers';
import { load } from '../Loading.vue';

type data = { comments: { status: 'sending' | 'sent' | null, val: string }, loaded: boolean };

export default defineComponent({
    emits: ['load'],
    async beforeMount(){
        if(this.$store.hasModule('study'))
            return;

        this.loaded = false;
        const data = await load();
        // if('notFound' in data || !data.usedAt)
        //     return this.$router.push(`/token/${this.$route.params.token}`);

        this.$emit('load', data);
        this.loaded = true;
    },
    data: (): data => ({ comments: { status: null, val: '' }, loaded: true }),
    methods: {
        async sendComments(){
            this.comments.status = 'sending';
            await sendMessage(this.comments.val, 'comments');
            this.comments.status = 'sent';
        }
    }
});
