<template>
    <final-page @load='onLoad'>
        <h1>
            תודה על {{ $parse('השתתפותך' ,'השתתפותך' ,'השתתפותכם') }}
            במחקר!
        </h1>
        <div v-if='hasCorona || hasCorona === null'>
            כדי שנוכל לקבל תמונה יותר ברורה,
            נשמח אם {{ $parse('תוכל' ,'תוכלי' ,'תוכלו') }}
            לחזור למלא את השאלון גם בימים הבאים, עד ש{{ $parse('תחלים', 'תחלימי', 'תחלימו')}}
            מהקורונה. הכניסה תיעשה באמצעות אותו הקישור ש{{ $parse('נכנסת', 'נכנסת', 'נכנסתם') }}
            איתו הפעם.
        </div>
        <div>
            {{ $parse('אתה מוזמן' , 'את מוזמנת' , 'אתם מוזמנים') }}
            לפנות אלי בכל שאלה שיש {{ $parse('לך' , 'לך' , 'לכם') }}
            במייל michal.haran@gmail.com או בטלפון/ווטסאפ <div id='phone'>{{ $store.state.studyPhone }}</div>.
        </div>
        <div>
            {{ $parse('תוכל' , 'תוכלי' , 'תוכלו') }} <a :href='pdfPath'>ללחוץ כאן</a>
            כדי להוריד את טופס ההסכמה ש{{ $parse('מילאת' , 'מילאת' , 'מילאתם') }}
            בתחילת השאלון.
        </div>
        <div>
            חשוב שיהיו כמה שיותר אנשים שישתתפו במחקר שלנו,
            כדי שנוכל לקבל תוצאות כמה שיותר מהימנות.
            נשמח מאוד אם {{ $parse('תשתף' , 'תשתפי' , 'תשתפו') }}
            את כתובת האתר שלנו (<a href='https://coronastudy.co.il' target='_blank'>coronastudy.co.il</a>)
            כדי שאנשים נוספים יצטרפו למחקר.
        </div>
    </final-page>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { pdfPath, tokenResult } from '@/Study/helpers';
import { setHasCorona } from '@/Study/module';
import FinalPage from '@/Study/Pages/Final.vue';

export default defineComponent({
    components: { FinalPage },
    computed: {
        hasCorona(){
            return this.$store.state.study!.hasCorona;
        },
        pdfPath(){
            return pdfPath();
        }
    },
    methods: {
        onLoad(data: tokenResult){
            setHasCorona(Boolean(data.hasCorona));
        }
    }
});
</script>