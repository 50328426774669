<template>
    <section id='final' v-if='loaded'>
        <div class='parent'>
            <slot></slot>
            <img alt='' :src='require("@/assets/thank-you.gif") '/>
            <div id='comments' :class='{ withBorder: comments.status !== "sent" }'>
                <div v-if='comments.status !== "sent"'>
                    יש לך הערות על המראה של השאלון או על התוכן שלו? נשמח אם
                    {{ $parse('תרשום' , 'תרשמי') }}
                    אותן כאן:
                    <textarea v-model='comments.val'></textarea>
                    <button type='button' study-button @click='sendComments()'>
                        <span v-if='!comments.status'>שלח הערות</span>
                        <span v-else>שולח...</span>
                    </button>
                </div>
                <h3 v-else>
                    תודה על ההערות! אנחנו מאוד מעריכים את זה
                </h3>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { sendMessage } from '../helpers';
import { load } from '../Loading.vue';

type data = { comments: { status: 'sending' | 'sent' | null, val: string }, loaded: boolean };

export default defineComponent({
    emits: ['load'],
    async beforeMount(){
        if(this.$store.hasModule('study'))
            return;

        this.loaded = false;
        const data = await load();
        // if('notFound' in data || !data.usedAt)
        //     return this.$router.push(`/token/${this.$route.params.token}`);

        this.$emit('load', data);
        this.loaded = true;
    },
    data: (): data => ({ comments: { status: null, val: '' }, loaded: true }),
    methods: {
        async sendComments(){
            this.comments.status = 'sending';
            await sendMessage(this.comments.val, 'comments');
            this.comments.status = 'sent';
        }
    }
});
</script>

<style lang="scss">
#final .parent {
    margin: 3rem 0;
    max-width: 26rem;
    
    & > div {
        margin: 1rem 0;
        padding: 0 1.5rem;
    }
    & img {
        margin: 0 auto;
        max-width: 10rem;
    }

    & #phone {
        display: inline;
    }

    & #comments {
        &.withBorder {
            border-top: $borderLight;
        }

        padding-top: 1.5rem;

        & textarea {
            width: 100%;
            margin: 1rem 0;
            box-shadow: unset;
        }

        & h3 {
            background-color: $swatchA;
            border-radius: $corner;
            @include shadow;
            margin: 0 auto 1rem;
            width: fit-content;
            padding: 1rem;
            font-size: 1.1rem;
        }

        & button {
            margin: 2rem auto 1rem;
            display: block;
        }
    }
}
</style>